import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const CardContainer = styled.div`
  color: ${props => (props.textColor ? props.textColor : "#000")};
  text-align: center;

  @media only screen and (min-width: 64em) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
  }
`
const CardImage = styled.img`
  height: auto;
  max-width: 100%;
  object-fit: contain;
  vertical-align: middle;
  width: auto;
`

const ImageWrapper = styled.div`
  text-align: center;

  @media only screen and (min-width: 64em) {
    flex: 0 0 23%;
  }
`

const ContentWrapper = styled.div`
  p:last-of-type {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 64em) {
    flex: 0 0 73%;
  }
`

const CardTitle = styled.h3`
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 0;
`

const SideImageCard = ({
  textColor,
  imageAlt,
  imageSrc,
  titleText,
  children,
}) => {
  return (
    <CardContainer textColor={textColor}>
      <ImageWrapper>
        <CardImage src={imageSrc} alt={imageAlt} />
      </ImageWrapper>

      <ContentWrapper>
        <CardTitle>{titleText}</CardTitle>
        {children}
      </ContentWrapper>
    </CardContainer>
  )
}

SideImageCard.propTypes = {
  children: PropTypes.node,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  textColor: PropTypes.string,
  titleText: PropTypes.string,
}

SideImageCard.defaultProps = {
  textColor: "#000",
}

export default SideImageCard
