import React from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"

import GreenWave from "../images/green-wave.svg"
import HeroImage from "../images/event-bg.jpg"
import TopRight from "../assets/images/top-right.svg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkButton from "../components/linkButton"
import SideImageCard from "../components/side-image-card"
import RibbonLabel from "../components/ribbon-label"

const BlueWaveImage = styled.img`
  vertical-align: middle;
  height: auto;
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
`
const HeroContainer = styled.div`
  background-image: url(${HeroImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 2rem 0;

  img:first-of-type {
    vertical-align: middle;
    height: auto;
    width: auto;
  }

  @media (min-width: 768px) {
    padding: 5rem 1.5rem;
  }
`

const FancyIntoContainer = styled.section`
  position: relative;
  padding-top: 102px;
  background-color: #9ac355;

  @media (min-width: 768px) {
    background-color: #fff;
  }
`
const IconWithText = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;

  img {
    vertical-align: middle;
    margin-right: 20px;
  }

  p {
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }

  @media (min-width: 768px) {
    p {
      font-size: 22px;
      line-height: 26px;
    }
  }
`

const Events = ({ data }) => {
  const listOneItems = [
    {
      titleText: "Register",
      imageSrc: data.ticketIcon.publicURL,
      imageAlt: "",
      content: (
        <p>
          Register for free online and get your media kit including pre-made
          social media posts, invitations, and hosting information.
        </p>
      ),
    },
    {
      titleText: "Set Up Shop",
      imageSrc: data.picnicTableIcon.publicURL,
      imageAlt: "",
      content: (
        <p>
          Hold your at-home brewfest with your family or small group of friends.
          Decorate, set up beer tents and name your backyard brewfest. On or
          about July 17, everyone brings their favorite brews to share. Wine,
          kombucha or other drinks are great choices too.
        </p>
      ),
    },
    {
      titleText: "Get the Goods",
      imageSrc: data.beerIcon.publicURL,
      imageAlt: "",
      content: (
        <p>
          Commemorate your experience with official PBPB pint glasses. Buy yours
          online and pick them up at a local vendor before the festival. Your
          purchase will support this year’s beneficiary organizations.
        </p>
      ),
    },
    {
      titleText: "Donate",
      imageSrc: data.donateIcon.publicURL,
      imageAlt: "",
      content: (
        <p>
          Your brewfest is welcome to donate more than the price of a glass if
          you feel so inclined.
        </p>
      ),
    },
    {
      titleText: "Share",
      imageSrc: data.shareIcon.publicURL,
      imageAlt: "",
      content: (
        <p>
          Take pictures, post on social media, and tell your friends about PBPB!
          Share your posts using the hashtags #PBPB2021 and
          #prescottbackyardbrewfest.
        </p>
      ),
    },
  ]

  const listTwoItems = [
    {
      titleText: "Recommend",
      imageSrc: data.starIcon.publicURL,
      imageAlt: "",
      content: (
        <p>
          We promote responsible event recommendations. Please do your part to
          make your brewfest a safe, healthy and positive experience.
        </p>
      ),
    },
    {
      titleText: "Donate",
      imageSrc: data.donateIcon.publicURL,
      imageAlt: "",
      content: (
        <p>
          We donate a portion of all funds to the local organizations that did
          not get donations due to the cancellation of Prescott’s NORMAL
          brewfests.
        </p>
      ),
    },
    {
      titleText: "Connect",
      imageSrc: data.connectIcon.publicURL,
      imageAlt: "",
      content: (
        <p>
          We will promote and feature the organizations that sponsor this event.
        </p>
      ),
    },
  ]

  return (
    <Layout footerSeparatorEnabled={false} footerGraphicEnabled={false}>
      <SEO title="PBPB Event" description="About Us Meta Description" />

      <FancyIntoContainer>
        <HeroContainer>
          <div className="container">
            <div className="col-lg-5 ">
              <img
                src={data.pbpbLogo.publicURL}
                alt="Prescott Backyard Pandemic Brewfest Logo"
              />

              <div className="my-1">
                <RibbonLabel text="UPCOMING CHARITABLE EVENT" />
              </div>

              <h1 className="mt-0">
                Second Annual Prescott Backyard Pandemic Brewfest (PBPB)
              </h1>

              <IconWithText>
                <img src={data.lunchBoxIcon.publicURL} alt="lunch box icon" />
                <p>Saturday, July 17, 2021</p>
              </IconWithText>

              <IconWithText>
                <img
                  src={data.locationIcon.publicURL}
                  alt="location pin icon"
                />
                <p>Your backyard</p>
              </IconWithText>

              <div>
                <a
                  className="button button--secondary mt-1"
                  href="https://www.classy.org/give/343650/#!/donation/checkout"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Register Here
                </a>
              </div>
            </div>
          </div>
        </HeroContainer>

        <BlueWaveImage src={TopRight} alt="" />
      </FancyIntoContainer>

      <section className="container py-3 py-lg-6">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-9 text-center">
            <h1 className="mt-0">About PBPB</h1>

            <p>
              PBPB 2021 is your chance to enjoy a Prescott-style summer festival
              with your friends and family while supporting local nonprofits and
              businesses.
            </p>

            <p>
              The concept is simple: register for free and start planning your
              brewfest. With a donation of $25, you’ll also get a PBPB pint
              glass and some virtual swag (freebies, discounts at local
              businesses, and more). Enjoy popular or local beer along with
              foods that remind you of an outdoor summertime festival. Your
              event can be as large or intimate as you’re comfortable with!
            </p>

            <p>
              If you’d like to purchase additional glasses, we will have
              discounts on 6, 12, and 24 packs. All profits from pint glass
              purchases and any additional donations will benefit the PUSD
              Education Foundation, and the Northern Arizona Technology Alliance
              (NATA).
            </p>
          </div>

          {/* <div className="col-xs-12 col-lg-4 text-center mt-2 mt-lg-3">
            <a
              className="img-link"
              href="https://www.bgccaz.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className="img-fluid"
                src={data.BGCCALogo.publicURL}
                alt="Boys and girls club of central Arizona logo"
              />
            </a>
          </div> */}

          <div className="col-xs-12 col-lg-4 text-center mt-3">
            <a className="img-link" href="/">
              <img
                className="img-fluid"
                src={data.nataLogo.publicURL}
                alt="Northern Arizona Technology Alliance"
              />
            </a>
          </div>
        </div>
      </section>

      <section style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="bg-primary col-xs-12 col-lg-6">
          <div className="split-list__wrapper">
            <h1 className="color-white mt-0">What We Do</h1>

            <ul className="split-list">
              {listOneItems.map((listItem, index) => (
                <li key={index}>
                  <SideImageCard
                    imageAlt={listItem.imageAlt}
                    imageSrc={listItem.imageSrc}
                    textColor="#fff"
                    titleText={listItem.titleText}
                  >
                    {listItem.content}
                  </SideImageCard>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="bg-secondary col-xs-12 col-lg-6 bg-full-bottom"
          style={{ backgroundImage: `url(${GreenWave})` }}
        >
          <div className="split-list__wrapper">
            <h1 className="color-white mt-0">What We Do</h1>

            <ul className="split-list bg-wavy-dots">
              {listTwoItems.map((listItem, index) => (
                <li key={index}>
                  <SideImageCard
                    imageAlt={listItem.imageAlt}
                    imageSrc={listItem.imageSrc}
                    textColor="#fff"
                    titleText={listItem.titleText}
                  >
                    {listItem.content}
                  </SideImageCard>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="py-7">
        <div className="container">
          <div className="row justify-content-center justify-content-lg-evenly align-items-center">
            <div className="col-xs-11 col-sm-8 col-lg-4 text-center">
              <img
                alt="Glass cup with the Prescott Backyard Pandemic Brewfest (PBPB) logo."
                className="img-fluid"
                src={data.PBPBGlass.publicURL}
              />
            </div>

            <div className="col-xs-11 col-sm-8 col-lg-4">
              <h1>Purchase a PBPB Pint Glass</h1>

              <p>
                Show your support for Prescott’s many wonderful nonprofit
                educational programs!
              </p>

              <a
                className="button button--secondary mt-2"
                href="https://www.classy.org/give/343650/#!/donation/checkout"
                rel="noopener noreferrer"
                target="_blank"
              >
                Purchase Glass
              </a>
            </div>
          </div>

          <div className="row justify-content-center justify-content-lg-evenly align-items-center mt-5">
            <div className="col-xs-11 col-sm-8 col-lg-4">
              <h1>Pickup Locations</h1>

              <p>
                Pickup locations will be announced before the brewfest. If you
                don’t live in Prescott but still want to participate, there will
                be shipping options at checkout.
              </p>
            </div>
            <div className="col-xs-11 col-sm-8 col-lg-4 text-center">
              <img
                className="img-fluid"
                src={data.manHoldingBeer.publicURL}
                alt="Man in a store holding a beer."
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary triangle-below color-white py-7">
        <div className="container">
          <div className="row justify-content-center justify-content-lg-evenly  align-items-center">
            <div className="col-xs-11 col-sm-8 col-lg-4 text-center">
              <img
                alt="puzzle pieces icon"
                className="img-fluid"
                src={data.PuzzlePieces.publicURL}
              />
            </div>

            <div className="col-11 col-sm-8 col-lg-4">
              <h1>Become a Sponsor</h1>

              <p>
                Contact us if you’d like to become a sponsor or partner vendor.
                PBPB vendors can be featured as glass pickup spots and preferred
                locations for purchasing food and beverages.
              </p>

              <a
                className="button button--secondary mt-2"
                href="mailto:info@tech-arizona.com"
              >
                Get Involved
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-3 mb-7">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-xs-12 text-center">
              <h1>PBPB Sponsors and Partner Organizations</h1>
            </div>

            <div className="col-xs-12 col-lg-6 text-center mt-3">
              <a
                className="img-link"
                href="https://eightfold.io/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className="img-fluid"
                  src={data.eightfoldLogo.publicURL}
                  alt="eightfold technology logo"
                />
              </a>
            </div>

            <div className="col-xs-12 col-lg-6 text-center mt-3">
              <a
                className="img-link"
                href="https://kasadia.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className="img-fluid"
                  src={data.kasadiaLogo.publicURL}
                  alt="Kasadia Logo"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    PuzzlePieces: file(relativePath: { eq: "puzzle-pieces.png" }) {
      publicURL
    }
    PBPBGlass: file(relativePath: { eq: "pbpb-glass.jpg" }) {
      publicURL
    }
    manHoldingBeer: file(relativePath: { eq: "man-holding-beer.jpg" }) {
      publicURL
    }
    ticketIcon: file(relativePath: { eq: "ticket.svg" }) {
      publicURL
    }
    starIcon: file(relativePath: { eq: "star.svg" }) {
      publicURL
    }
    connectIcon: file(relativePath: { eq: "connect.svg" }) {
      publicURL
    }
    beerIcon: file(relativePath: { eq: "beer.svg" }) {
      publicURL
    }
    picnicTableIcon: file(relativePath: { eq: "picnic-table.svg" }) {
      publicURL
    }
    shareIcon: file(relativePath: { eq: "share.svg" }) {
      publicURL
    }
    donateIcon: file(relativePath: { eq: "donate.svg" }) {
      publicURL
    }
    eightfoldLogo: file(relativePath: { eq: "eightfold-full-logo.svg" }) {
      publicURL
    }
    kasadiaLogo: file(relativePath: { eq: "kasadia-logo.svg" }) {
      publicURL
    }
    pbpbLogo: file(relativePath: { eq: "pbpb-logo.png" }) {
      publicURL
    }
    locationIcon: file(relativePath: { eq: "location.svg" }) {
      publicURL
    }
    lunchBoxIcon: file(relativePath: { eq: "lunch-box.svg" }) {
      publicURL
    }
    nataLogo: file(relativePath: { eq: "logo.svg" }) {
      publicURL
    }
    BGCCALogo: file(relativePath: { eq: "BGCCALogo.png" }) {
      publicURL
    }
  }
`

export default Events
