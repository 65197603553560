import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const StyledLink = styled(Link)`
  background-color: ${props => (props.bgColor ? props.bgColor : "#8DC63F")};
  border-radius: 50px;
  color: ${props => (props.color ? props.color : "#fff")};
  cursor: pointer;
  display: inline-block;
  font-size: 22px;
  line-height: 26px;
  padding: 0.727272em 1.2em;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;
`

const LinkButton = ({ to, children, bgColor, color }) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <StyledLink to={to} color={color} bgColor={bgColor}>
        {children}
      </StyledLink>
    )
  }

  return (
    <a href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

export default LinkButton
