import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const RibbonContainer = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : "#48A49D")};
  box-sizing: border-box;
  color: ${props => (props.textColor ? props.textColor : "#fff")};
  display: inline-block;
  font-size: 16px;
  height: 40px;
  line-height: 19px;
  padding: 10px 12px;
  position: relative;
  text-transform: uppercase;

  span {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &::before,
  &::after {
    border-style: solid;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
  }

  &::before {
    border-color: ${props => (props.bgColor ? props.bgColor : "#48A49D")}
      transparent transparent transparent;
    border-width: 20px 20px 0 0;
    left: 100%;
    top: 0;
  }

  &::after {
    border-color: transparent transparent transparent
      ${props => (props.bgColor ? props.bgColor : "#48A49D")};
    border-width: 20px 0 0 20px;
    bottom: 0;
    left: 100%;
  }

  @media (min-width: 768px) {
    font-size: 22px;
    height: 50px;
    line-height: 26px;
    padding: 12px 16px;

    &::before {
      border-width: 25px 25px 0 0;
    }
    &::after {
      border-width: 25px 0 0 25px;
    }
  }
`

const RibbonLabel = ({ bgColor, textColor, text }) => {
  return (
    <RibbonContainer bgColor={bgColor} textColor={textColor}>
      <span>{text}</span>
    </RibbonContainer>
  )
}

RibbonLabel.propTypes = {
  bgColor: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
}

RibbonLabel.defaultProps = {
  bgColor: "#48A49D",
  textColor: "#fff",
}

export default RibbonLabel
